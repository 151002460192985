<template>
  <div id="app-cart">
    <p><span id="donate-phone-help">Need help?<br/>Call toll-free<br/>{{ donate_phone }}</span>Tributes can be added in the next step after clicking "Complete giving" below. MCC accepts Visa, Mastercard, Discover (U.S. only) and PayPal. PayPal cannot be used with monthly gifts at this time.</p>
    <transition name="fade" mode="out-in">
      <cart :cart="cart" :currency="currency" :editable="true" :processingForm="processingForm" v-if="cart.length > 0" key="cart"></cart>
      <div class="empty-cart" v-else key="empty">
        <p>Your basket is empty.</p>
        <p><a :href="baseUrl">Browse categories</a> or <a :href="project_list_url">view all available giving projects.</a></p>
      </div>
    </transition>
  </div>
</template>

<script>
import Cart from './Cart.vue';
import axios from 'axios';
import { CartEventBus } from '../event-bus.js';
import { CartApi, getCartBaseUrl } from '../cart-api.js';

export default {
  name: 'app',
  data () {
    return {
      cart: [],
      baseUrl: '/',
      currency: 'USD',
      suggestions: [],
      processingForm: false
    }
  },
  computed: {
    donate_phone: function () {
      return this.currency == 'USD' ? '1-888-563-4676' : '1-888-622-6337';
    },
    project_list_url: function () {
      return this.currency == 'USD' ? 'https://donate.mcc.org/projects' : 'https://donate.mcccanada.ca/projects';
    }
  },
  created() {
    // App communication happens over the EventBus
    window.CartEventBus = CartEventBus;
  },
  mounted() {
    CartEventBus.$on('removeItem', id => {
      var cart = this.cart;

      var removedItem = null;

      cart.forEach(function(item, index){
        if (item.cart_item_id === id) {
          removedItem = cart.splice(index, 1);
        }
      });

      CartEventBus.$emit('itemRemoved', cart, removedItem[0]);

      CartApi.delete(`api/cart/item/${id}`)
      .then(response => {
        // Force the state to reload so bfcache works right
        CartEventBus.$emit('loadCart');
      })
      .catch(error => {
        // If the item does not exist, do nothing. Else add it back.
        if (error.response.status == 404) {
        } else {
          cart.push(removedItem[0]);
        }
      });
    });

    CartEventBus.$on('addItem', item => {
      CartApi.post('api/cart/item', item)
      .then(response => {
        this.cart = response.data;
        CartEventBus.$emit('itemAdded', this.cart, this.cart.slice(-1)[0]);
        // Force the state to reload so bfcache works right
        CartEventBus.$emit('loadCart');
      })
      .catch(error => {
        if (error.response.status == 429) {
          alert(error.response.data.error);
        } else {
          alert('Sorry, the item could not be added to your basket. Try again in a few moments.');
        }
      });
    });

    CartEventBus.$on('loadCart', () => {
      CartApi.get('api/cart')
      .then(response => {
        this.cart = response.data;
        CartEventBus.$emit('cartLoaded', this.cart);
      })
      .catch(error => {
      });
    });

    CartEventBus.$on('loadCurrency', () => {
      CartApi.get('api/cart/currency')
      .then(response => {
        this.currency = response.data.currency;
      })
      .catch(error => {
      });
    });

    CartEventBus.$on('saveCart', () => {
      var self = this;
      this.processingForm = true;

      CartApi.post('api/cart/save', { cart: this.cart, currency: this.currency })
      .then(response => {
        this.processingForm = false;
        window.location = `${getCartBaseUrl()}new/cart/checkout`;
      })
      .catch(error => {
        self.processingForm = false;
        if (error.response.status == 400) {
          let errorList = '';

          jQuery.each(error.response.data, function(key, arr){
            errorList += arr.join('<br>')+'\n';
          });

          self.$swal('Oops...', errorList, 'error');
        } else {
          alert('Sorry, there was a problem saving your cart. Try again in a few moments.');
        }
      });
    });

    CartEventBus.$on('updateCurrency', currency => {
      this.currency = currency;
    });


    CartEventBus.$on('itemAdded', function(cart, item){
      // Google Analytics
      if (typeof ga !== 'undefined') {
        ga('ec:addProduct', {
          'id': item.nid,
          'name': item.title,
          'brand': item.is_giving_registry ? item.sub_title : '',
          'category': item.is_giving_registry ? 'Giving Registry' : 'Giving Project',
          'variant': item.recurrance,
          'price': item.amount,
          'quantity': item.qty
        });
        ga('ec:setAction', 'add');
        ga('send', 'event', 'UX', 'click', 'add to basket');
      }

      // Mixpanel Tracking
      if (typeof mixpanel !== 'undefined') {
          mixpanel.track("Add Item", {
              amount: item.amount,
              nid: item.nid,
              recurrance: item.recurrance,
              title: item.title,
              qty: item.qty
          });
      }

      // Facebook Pixel AddToCart
      if(typeof fbq !== 'undefined'){
        fbq('track', 'AddToCart', {
          value: item.amount
        });
      }
    });

    CartEventBus.$on('itemRemoved', function(cart, item){
        // Google Analytics
        if (typeof ga !== 'undefined') {
          ga('ec:addProduct', {
            'id': item.nid,
            'name': item.title,
            'brand': item.is_giving_registry ? item.sub_title : '',
            'category': item.is_giving_registry ? 'Giving Registry' : 'Giving Project',
            'variant': item.recurrance,
            'price': item.amount,
            'quantity': item.qty
          });
          ga('ec:setAction', 'remove');
          ga('send', 'event', 'UX', 'click', 'remove from basket');
        }

        // Mixpanel Tracking
        if (typeof mixpanel !== 'undefined') {
            mixpanel.track("Remove Item", {
                amount: item.amount,
                nid: item.nid,
                recurrance: item.recurrance,
                title: item.title,
                qty: item.qty
            });
        }
    });

    CartEventBus.$emit('loadCart');
    CartEventBus.$emit('loadCurrency');

    this.baseUrl = getCartBaseUrl();
  },
  components: {Cart}
}
</script>

<style lang="scss">
#donate-phone-help {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  text-align: right;
  font-size: 14px;
  max-width: 160px;
  line-height: 18px;
}

div.empty-cart {
  box-sizing: border-box;
  font-size: 18pt;
  width: 100%;
  padding: 10pt;
  text-align: center;
  background-color: #DDDDDD;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
.button-primary[disabled],
.button-primary[disabled]:hover {
  color: white;
  -webkit-animation: button-color-swap 2.0s infinite ease-in-out;
  animation: button-color-swap 2.0s infinite ease-in-out;
}

@keyframes button-color-swap {
  0%, 100% {
    background: #F0F0F0;
    border-color: #F0F0F0;
  } 50% {
    background: #777777;
    border-color: #777777;
  }
}
</style>
