import Vue from 'vue'
import VueSweetAlert from 'vue-sweetalert'
import App from './components/CartApp.vue'

Vue.use(VueSweetAlert)

new Vue({
  el: '#vue-mount-cart',
  render: h => h(App),
  mounted () {
    this.$swal.setDefaults({
      confirmButtonColor: '#0092ac'
    });
  }
})
