<template>
  <li>
    <div class="flex-column thumbnail"><img v-if="item.images" :src="item.images.mcc_project_homepage"></div>
    <div class="flex-column detail">
      <h3><a :href="item.url">{{ item.title }}</a></h3>
      <p v-if="item.is_giving_registry">Giving Registry: {{ item.sub_title }}</p>
      <div v-else v-html="item.description"></div>
      <p>
        <a href="javascript:void(0)" @click="remove">Remove this donation</a> | <a :href="item.url">Read more</a>
      </p>
      <div class="consent-group" v-if="item.is_giving_registry && editable" >
        <label><input type="radio" value="yes" v-model="item.data_sharing" v-bind:name="consentName" required> You may share my name and contact information with this person or group.</label>
        <label><input type="radio" value="no" v-model="item.data_sharing" v-bind:name="consentName"> You may not share my name and contact information with this person or group.</label>
      </div>
      <p v-else-if="item.is_giving_registry">{{sharingMsg}}</p>
    </div>
    <div v-if="editable" class="flex-column edit">
      <div class="money-input">
        <input v-model.number="item.amount" type="number" step="0.01" min="0" autocomplete="off" required>
        <div class="input-symbol"><span>$</span></div>
      </div>
      <div class="recurrance-group">
        <label><input type="radio" value="one_time" v-model="item.recurrance"> One-time</label>
        <label><input type="radio" value="monthly" v-model="item.recurrance"> Monthly</label>
      </div>
    </div>
    <div v-else class="flex-column">
      ${{ item.amount }}
      {{ item.total }}
    </div>
  </li>
</template>

<script>
import { CartEventBus } from '../event-bus.js';
import _ from 'lodash';

export default {
  props: ['item', 'editable'],
  computed: {
    sharingMsg: function() {
      return this.item.data_sharing == 'yes' ? 'You may share my name and contact information with this person or group.' : 'You may not share my name and contact information with this person or group.';
    },
    consentName: function() {
      return 'data-sharing-' + this.item.cart_item_id;
    }
  },
  data: function() {
    return {
      bounce: null
    };
  },
  watch: {
    'item.amount': _.debounce(function(newAmount, oldAmount) {
      this.item.total = this.item.qty * this.item.amount;
      if (this.item.amount >= 0) {
        CartEventBus.$emit('updateItem', this.item.cart_item_id, { amount: this.item.amount });
      }

      // Mixpanel Tracking
      if (oldAmount > 0 && newAmount > oldAmount) {
          if (typeof mixpanel !== 'undefined') {
              mixpanel.track("Increase Item Amount");
          }
      }

      // Mixpanel Tracking
      if (oldAmount > 0 && newAmount < oldAmount) {
          if (typeof mixpanel !== 'undefined') {
              mixpanel.track("Decrease Item Amount");
          }
      }
    }, 500),
    'item.qty': function(newAmount) {
      this.item.total = this.item.qty * this.item.amount;
      CartEventBus.$emit('updateItem', this.item.cart_item_id, { qty: this.item.qty });
    },
    'item.recurrance': function(newRecurrance) {
      CartEventBus.$emit('updateItem', this.item.cart_item_id, { recurrance: this.item.recurrance });
    },
    'item.data_sharing': function(newDataSharing) {
      CartEventBus.$emit('updateItem', this.item.cart_item_id, { data_sharing: this.item.data_sharing });
    }
  },
  methods: {
    remove: function() {
      CartEventBus.$emit('removeItem', this.item.cart_item_id);
    }
  }
}
</script>

<style lang="scss">
li.cart-item {
  display: flex;
  flex-direction: row;
  padding-top: 8pt;
  padding-bottom: 8pt;
  margin-top: -1px;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  transition: all .4s;
}

.flex-column {
  &.detail {
    margin-right: 10px;
    flex-grow: 1;

    p {
      padding: 0 !important;
      margin: 2pt;
    }

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  @media (max-width: 719px) {
    &.thumbnail {
      display: none;
    }
  }

  &.thumbnail {
    max-width: 120pt;
    padding-right: 10pt;
    flex-shrink: 0;

    img {
      width: 100%;
    }
  }

  &.edit {

  }
}

.consent-group {
  margin: 10px 0 10px 4px;
}

.recurrance-group {
  label {
    font-size: 13pt;
    margin: 0;
    padding: 0;
  }

  input {
    position: relative;
    top: -2px;
  }
}

.money-input {
  position: relative;

  input {
    text-align: right;
    font-size: 18pt;
    font-weight: bold;
    width: 144pt;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .input-symbol {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #BBBBBB;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 35pt;

    span {
      color: white;
      font-size: 16pt;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>