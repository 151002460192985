<template>
  <div>
    <form v-on:submit.prevent="saveCart">
    <transition-group name="cart" tag="ul" class="cart">
      <cart-item v-for="item in cart" :item="item" :key="item.cart_item_id" :editable="editable" class="cart-item"></cart-item>
    </transition-group>
    <div class="cart-group">
      <div class="controls">
        <button class="primary-button button-primary give-button" :disabled="processingForm">Complete giving</button>
      </div>
      <div class="totals">
        <div>Total: {{ total }}</div>
        <div>in {{ currency }}</div>
        <a href="javascript:void(0)" @click="changeCurrency(otherCurrency)">Change to {{ otherCurrency }}</a>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import CartItem from './CartItem.vue';
import { CartEventBus } from '../event-bus.js';
import currency from '../currency.js';

export default {
  props: ['cart', 'editable', 'currency', 'processingForm'],
  computed: {
    total: function() {
      var total = 0;

      this.cart.forEach(function(item){
        total += item.total;
      });

      return currency(total);
    },
    otherCurrency: function() {
      if (this.currency == 'CAD') {
        return 'USD';
      } else {
        return 'CAD';
      }
    }
  },
  methods: {
    saveCart: function() {
      CartEventBus.$emit('saveCart');
    },
    changeCurrency: function(newCurrency) {
      CartEventBus.$emit('updateCurrency', newCurrency);

      // Mixpanel Tracking
      if (typeof mixpanel !== 'undefined') {
          mixpanel.track("Change Currency");
      }
    }
  },
  components: {'cart-item': CartItem}
}

</script>

<style lang="scss">
.cart-enter, .cart-leave-to{
  opacity: 0;
  transform: translateY(30px);
}

.cart-leave-active {
  position: absolute;
}

ul.cart {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cart-group {
  margin-top: 10pt;
  text-align: right;
}

.controls {
  display: inline-block;
  float: left;
}

.totals {
  display: inline-block;
  text-align: right;

  div {
    font-size: 20pt;
    font-weight: bold;
  }
}
</style>